var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "container-no-padding" },
    [
      _c(
        "b-modal",
        {
          attrs: { "hide-footer": "", title: _vm.titleText },
          model: {
            value: _vm.modalVisibility.addOwnedItem,
            callback: function ($$v) {
              _vm.$set(_vm.modalVisibility, "addOwnedItem", $$v)
            },
            expression: "modalVisibility.addOwnedItem",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addItem.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.ownedData },
                    model: {
                      value: _vm.newItem.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "value", $$v)
                      },
                      expression: "newItem.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    "aria-label": "Add button",
                  },
                },
                [_vm._v("Add")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "danger", "aria-label": "Cancel button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleModal("addOwnedItem", { newItem: {} })
                    },
                  },
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "div",
            { staticClass: "monitoring-identities-card" },
            [
              _c("b-table", {
                staticClass: "table",
                attrs: { items: _vm.tableData, fields: _vm.fields },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(value)",
                      fn: function (data) {
                        return [
                          _c("b-form-input", {
                            attrs: { disabled: _vm.isEditMode },
                            model: {
                              value: data.item.value,
                              callback: function ($$v) {
                                _vm.$set(data.item, "value", $$v)
                              },
                              expression: "data.item.value",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "head(value)",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.identityTypeStringPlural) +
                              "\n      "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "cell(active)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "active-button" },
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  name: "check-button",
                                  switch: "",
                                  size: "lg",
                                  disabled: _vm.isEditMode,
                                },
                                model: {
                                  value: data.item.active,
                                  callback: function ($$v) {
                                    _vm.$set(data.item, "active", $$v)
                                  },
                                  expression: "data.item.active",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(actions)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "manage-buttons" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "save-button",
                                  attrs: {
                                    "aria-label": "Save button",
                                    variant: "primary",
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveChanges(data.item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.buttonText) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "delete-button",
                                  attrs: {
                                    "aria-label": "Delete button",
                                    variant: "danger",
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleModal("confirmDelete", {
                                        itemToDelete: data.item,
                                      })
                                    },
                                  },
                                },
                                [_vm._v("\n            Remove\n          ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3277637828
                ),
              }),
              _c("div", { staticClass: "add-identities" }, [
                _vm.ownedData?.length
                  ? _c(
                      "div",
                      { staticClass: "add-owned-identity" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleModal(
                                  "addOwnedItem",
                                  _vm.refreshNewItem
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          Add " +
                                _vm._s(_vm.identityTypeStringSingular) +
                                " from your account\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "add-new-identity" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleModal(
                              "addAction",
                              _vm.refreshNewItem
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          Add external " +
                            _vm._s(_vm.identityTypeStringSingular) +
                            " \n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c(
            "monitoring-no-identity",
            {
              attrs: {
                "identity-type": _vm.identityTypeStringPlural.toLowerCase(),
              },
            },
            [
              _c(
                "div",
                { staticClass: "add-buttons" },
                [
                  _vm.ownedData?.length
                    ? _c(
                        "div",
                        { staticClass: "add-owned-item-button" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleModal(
                                    "addOwnedItem",
                                    _vm.refreshNewItem
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          Add " +
                                  _vm._s(_vm.identityTypeStringSingular) +
                                  " from your account\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModal(
                            "addAction",
                            _vm.refreshNewItem
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        Add external " +
                          _vm._s(_vm.identityTypeStringSingular) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
      _c(
        "b-modal",
        {
          attrs: { "hide-footer": "", title: _vm.titleText },
          model: {
            value: _vm.modalVisibility.addAction,
            callback: function ($$v) {
              _vm.$set(_vm.modalVisibility, "addAction", $$v)
            },
            expression: "modalVisibility.addAction",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addItem.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-input", {
                    attrs: { placeholder: _vm.placholderText },
                    model: {
                      value: _vm.newItem.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "value", $$v)
                      },
                      expression: "newItem.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    "aria-label": "Add button",
                  },
                },
                [_vm._v("Add")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "danger", "aria-label": "Cancel button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleModal("addAction", { saveItem: {} })
                    },
                  },
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-footer": "",
            title: "Are you sure you want to delete?",
          },
          model: {
            value: _vm.modalVisibility.confirmDelete,
            callback: function ($$v) {
              _vm.$set(_vm.modalVisibility, "confirmDelete", $$v)
            },
            expression: "modalVisibility.confirmDelete",
          },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: {
                variant: "secondary",
                block: "",
                "aria-label": "Cancel button",
              },
              on: {
                click: function ($event) {
                  return _vm.toggleModal("confirmDelete", { itemToDelete: {} })
                },
              },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: {
                variant: "danger",
                block: "",
                "aria-label": "Confirm button",
              },
              on: { click: _vm.deleteItem },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }